<template>
  <div class="page">
    <LoadingOverlay v-if="isUploading" />
    <div :class="fromCampaignPage ? '' : 'register'">
      <Registration
        v-if="!getCandidateCreated"
        :customer="getCustomerID"
        :organisationID="getOrgID"
        :locationID="getLocID"
        :profilePhoto="profilePhoto"
        :isSubmitted="isSubmitted"
      />
      <div
        v-else-if="getCandidateCreated"
        class="jumbotron bg-white text-center m-auto"
      >
        <h1 class="text-capitalize" name="user_first_name">
          Welcome {{ candidateProfile?.first_name }}!
        </h1>
        <div class="login">
          <div class="message">
            <p>Registration Successful!</p>
            <p>
              Please ensure your CV has been uploaded successfully. All
              additional information and supporting documents will assist in
              identifying suitable positions, which will be highlighted to you.
            </p>
            <p>
              Manage your applications here, so please continue to log in and
              check for messages and updates.
            </p>
            <p>
              We have emailed a confirmation of your registration – but please
              check your Junk mail.
            </p>
            <p
              class="mb-4"
              :name="`registered_cand-id-${candidateProfile.candidate_uid}`"
            >
              THANKS FOR REGISTERING!
            </p>
          </div>
          <div>
            <button
              v-if="getOrgVariant == Variant.campaign"
              @click="redirectToUserRegister()"
              class="btn rounded-0 px-5 btn-primary btn-lg"
            >
              Sign up Again
            </button>
            <button
              v-else
              @click="redirectToLogin()"
              class="btn rounded-0 px-5 btn-primary btn-lg"
            >
              Login
            </button>
          </div>
        </div>
      </div>
      <CToaster v-if="toast" :autohide="toastHide" position="bottom-right">
        <CToast :class="toastClass" :show="true">{{ toastMessage }}</CToast>
      </CToaster>
    </div>
  </div>
</template>

<script>
import { LOGIN_URL,TFW_LOGIN_URL, Variant } from "@/helpers/helper";
import { mapGetters, mapActions } from "vuex";
import Registration from "@/containers/CandidateProfile/Registration";

export default {
  name: "Register",
  components: {
    Registration,
  },
  data() {
    return {
      Variant,
      profilePhoto: {},
      isSubmitted: false,
    };
  },
  computed: {
    ...mapGetters([
      "toast",
      "toastMessage",
      "toastClass",
      "toastHide",
      "candidateProfile",
      "getUserDetails",
      "uploadResponse",
      "randomUuid",
      "getOrgVariant",
      "getUser",
      "campaignFieldsData",
      "fromCampaignPage",
      "getCandidateCreated",
    ]),
    isUploading() {
      return this.uploadResponse !== 0 ? true : false;
    },
    getCustomerID() {
      const {
        query: { id: qid },
        params: { id: pid, org_id },
      } = this.$route;
      let id = parseInt(qid || pid);
      return id;
    },
    getOrgID() {
      const {
        params: { org_id },
      } = this.$route;
      let id = org_id ? parseInt(org_id) : null;
      return id;
    },
    getLocID() {
      const {
        params: { loc_id },
      } = this.$route;
      return loc_id ? parseInt(loc_id) : null;
    },
    isTFW() {
      return this.$route?.path.includes("/tfw") || false;
    },
  },
  methods: {
    ...mapActions([
      "createCandidate",
      "uploadPhoto",
      "multipleFileUpload",
      "deleteTempDocs",
      "resetCandidate",
      "clearCampaignFieldsData",
      "setCandidateCreated",
    ]),
    create(data) {
      this.isSubmitted = true;
      let { payload, docFiles } = data;
      let appendAction = [this.createCandidate(payload)];
      appendAction = [...appendAction, this.deleteTempDocs(this.randomUuid)];
      Promise.all(appendAction)
        .then(async (res) => {
          const { customer_uid, candidate_uid, user_id } =
            this.candidateProfile;
          docFiles.forEach(async (element) => {
            let { file, docType } = element;
            let { code: document_type } = docType;
            payload = {
              file,
              document_type,
              customer_uid,
              candidate_uid,
              user_id,
            };
            if (document_type === 1) {
              await this.multipleFileUpload(payload);
            } else this.multipleFileUpload(payload);
          });
          this.updatePhoto();
        })
        .finally(() => {
          this.isSubmitted = false;
        });
    },
    upload(payload) {
      this.profilePhoto = payload;
      this.$store.commit("SET_UPLOADED");
    },
    updatePhoto() {
      let payload = this.profilePhoto;
      const document_type = payload.document_type || 1;
      const { customer_uid, candidate_uid, user_id } = this.candidateProfile;
      if (payload && payload.name) {
        if (payload.isPhotoUpload) {
          this.uploadPhoto({
            customer_uid,
            candidate_uid,
            user_id,
            [payload.name]: payload.value,
            document_type,
            ["fileName"]: payload.document_name,
            blobData: payload?.blobData,
          });
        }
      }
    },
    redirectToLogin() {
      if(this.isTFW){
        this.$router.push({ path: TFW_LOGIN_URL() });
      }
      else{
        this.$router.push({ path: LOGIN_URL() });
      }
    },
    redirectToUserRegister() {
      this.resetCandidate();
    },
  },
  beforeRouteLeave(to, from, next) {
    if (this.campaignFieldsData.length) this.clearCampaignFieldsData();
    next();
  },
  created() {
    this.setCandidateCreated(false)
  }
};
</script>

<style lang="scss" scoped>
.message {
  @media all and (min-width: 760px) {
    width: 40%;
    margin: auto;
  }
}
</style>
